const spMenu = document.getElementById("sp_btn");
const spMenuList = document.getElementById("sp_list");

spMenu.addEventListener("click", (element) => {
    const target = element.target as Element;
    if(target.classList.contains("open")){
        target.classList.remove("open");
        spMenuList.classList.remove("open");
        spMenuList.classList.add("close");
    }else{
        target.classList.add("open");
        spMenuList.classList.remove("close");
        spMenuList.classList.add("open");
    }
})

export { spMenu }