// 自宅の住所
const autoInputAddress = () => {
    const zipInputArea = <HTMLInputElement>document.getElementById("zipcode");
    const prefecture = <HTMLInputElement>document.getElementById("prefecture");
    const otherAddress = <HTMLInputElement>document.getElementById("otherAddress");
    zipInputArea.addEventListener('input', event => {
        if (!(event.target instanceof HTMLInputElement)) {
            return;
          }
        fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${ event.target.value }`)
        .then((responce) => responce.json())
        .then((data) => {
            prefecture.value = data.results[0].address1
            otherAddress.value = data.results[0].address2 + data.results[0].address3
        })
    })
}

// 売却相談
const autoInputAddressForSale = () => {
    const zipInputArea = <HTMLInputElement>document.getElementById("zipcode02");
    const prefecture = <HTMLInputElement>document.getElementById("prefecture02");
    const otherAddress = <HTMLInputElement>document.getElementById("otherAddress02");
    zipInputArea.addEventListener('input', event => {
        if (!(event.target instanceof HTMLInputElement)) {
            return;
          }
        fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${ event.target.value }`)
        .then((responce) => responce.json())
        .then((data) => {
            prefecture.value = data.results[0].address1
            otherAddress.value = data.results[0].address2 + data.results[0].address3
        })
    })
}

export { autoInputAddress, autoInputAddressForSale }