const Loading = <HTMLDivElement>document.querySelector("#Loading")
let loadingTime: number = 2500 

window.addEventListener("load", () => {
    if(!sessionStorage.getItem("firstAccess")){
        sessionStorage.setItem("firstAccess", "true")
        Loading.style.animationDelay = "0.5s"
        Loading.style.animationDuration = "1.0s"
        Loading.style.animationName = "fadeOut"
        Loading.style.animationFillMode = "forwards"
    }else{
        loadingTime = 1000
        Loading.style.animationDelay = "0.5s"
        Loading.style.animationDuration = "0.5s"
        Loading.style.animationName = "fadeOut"
        Loading.style.animationFillMode = "forwards"
    }
    setTimeout(() => {
        Loading?.classList.add('close');
    }, loadingTime)
    
})


export { Loading }
