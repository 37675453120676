const main =  () => {
    const mediaWidth: number = window.innerWidth
    const point: number = mediaWidth > 768 ? 1000 : 600

    window.addEventListener("scroll", () => {
        const footer = <HTMLDivElement>document.getElementById("footer");
        const topBtn = <HTMLAnchorElement>document.getElementById("topBtn")
        if(window.scrollY > footer.offsetTop - point){
            topBtn.classList.add("show")
        }else{
            topBtn.classList.remove("show")
        }
        
    })
}



export { main }